import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Pagination from '../Shared/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import getBaseUrl from '../BaseURL/getBaseUrl';

const Permission = () => {

    const baseUrl = getBaseUrl();
    const [permissions, setPermissions] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [perName, setPerName] = useState("");
    const [action, setAction] = useState("");
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [limit, setLimit] = useState(20);

    // Searching and Filtering 
    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearch(searchText);

    }
    const handlePerName = (e) => {
        const permissionText = e.target.value;
        setPerName(permissionText);

    }
    const handleFilter = (e) => {
        const status = e.target.value;
        setAction(status);

    }

    const handleDelete = (id) => {
        fetch(`${baseUrl}/permissions/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                toast.success(data.message);
                setPermissions(prevPermissions => prevPermissions.filter(permissions => permissions.id !== id));
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to delete permission.');
            }

            );
    };
    const navigate = useNavigate();
    const handleEdit = (permission) => {
        // navigate(`/edit-cat/${permission.cat_id}`, { state: { permission: permission } });
        console.log("edit working");
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const permissionsT = perName+"."+action; 
        const formData = new FormData();
        formData.append('permissions', permissionsT);
    
        try {
          const response = await axios.post(
            '${baseUrl}/permissions/create',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
    
          console.log(response.data);
          toast.success('permissions created successfully.');
        } catch (error) {
          console.error(error);
    
          toast.error('Failed to create permissions.');
        }
      };

    useEffect(() => {
        const getAllPermissions = async () => {
            const response = await fetch(`${baseUrl}/permissions?page=${page}&limit=${limit}&search=${search}`);
            const { success, data, totalItem } = await response.json();

            if (success) {
                setPermissions(data);
                console.log(data);
                setTotalPages(totalItem);
            } else {
                console.error("Error fetching data");
            }
        };
        getAllPermissions();
    }, [page, limit, search]);

    return (
        <div className='text-start '>
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Add Permission</h5>
                {/* <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                <form id='filter_form'>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="mb-3">
                                <label className="form-label">Section Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='permissions'
                                    onChange={handlePerName}
                                    placeholder="Permission Name"
                                />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="mb-3">
                                <label for="statusSelection" className="form-label">Action Permission</label>
                                <select
                                    className="form-select"
                                    name='status'
                                    id="statusSelection"
                                    onChange={handleFilter}
                                >
                                    <option selected>Select Action</option>
                                    <option className='text-success' value={"view"}>view</option>
                                    <option className='text-success' value={"create"}>create</option>
                                    <option className='text-warning' value={"update"}>update</option>
                                    <option className='text-danger' value={"delete"}>delete</option>
                                    
                                </select>
                            </div>
                        </div>
                    </div>

                </form>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary submit"
                >Add Permission</button>
            </div>
        </div>
        <div className="col-md-12 grid-margin stretch-card mt-3">
            <div className="card">
                <div className="card-body">
                    <h6 className="card-title">Permission List</h6>
                    <p className="text-muted mb-3"></p>
                    <div className="table-responsive">
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    
                                    <th>Permission Name</th>
                                   
                                    <th>Permission ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissions?.map((permission, key) =>
                                    <tr key={key}>
                                        <th>{++key}</th>
                                        <td>{permission.name}</td>
                                        
                                        <td>{permission.id}</td>
                                        <td>

                                            <button type="button"
                                                // onClick={() => handleEdit(permission)}
                                                class="btn btn-primary btn-icon me-1">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                            </button>
                                            {/* <button
                                                type="button"
                                                onClick={() => handleDelete(permission.id)}
                                                class="btn btn-danger btn-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>

                                            </button> */}
                                            </td>
                                    </tr>

                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
       


    </div>
    );
};

export default Permission;